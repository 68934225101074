<template>
  <div class="contact-area p-80">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="form ">
            <div class="mail-form">
              <form class="form-inputs" @submit.prevent="sendEmail">
                <h3>{{ $t('contactForm.title') }}</h3>
                <p>{{ $t('contactForm.fullName') }}</p>
                <label for="user_name">
                  <input type="text" id="user_name" name="user_name" v-model="name" :placeholder="$t('contactForm.fullName')" :class="{ 'is-invalid': submitted && $v.name.$error }">
                  <div v-if="submitted && !$v.name.required" class="invalid-feedback">{{ $t('contactForm.name') }}</div>
                </label>
                <p>{{ $t('contactForm.fullEmail') }}</p>
                <label for="user_email">
                  <input type="email" id="user_email" name="user_email" v-model="email" :placeholder="$t('contactForm.fullEmail')" :class="{ 'is-invalid': submitted && $v.email.$error }">
                  <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                    <span v-if="!$v.email.required">{{ $t('contactForm.email') }}</span>
                    <span v-if="!$v.email.email">{{ $t('contactForm.validEmail') }}</span>
                  </div>
                </label>
                <p>{{ $t('contactForm.fullSubject') }}</p>
                <label for="user_subject">
                  <input type="subject" id="user_subject" name="user_subject" v-model="subject" :placeholder="$t('contactForm.fullSubject')" :class="{ 'is-invalid': submitted && $v.subject.$error }">
                  <div v-if="submitted && !$v.subject.required" class="invalid-feedback">{{ $t('contactForm.subject') }}</div>
                </label>
                <p>{{ $t('contactForm.fullMessage') }}</p>
                <label for="message">
                  <textarea id="message"  name="message" v-model="message" :placeholder="$t('contactForm.fullMessage')" :class="{ 'is-invalid': submitted && $v.message.$error }"></textarea>
                  <div v-if="submitted && !$v.message.required" class="invalid-feedback">{{ $t('contactForm.message') }}</div>
                </label>
                <label for="checkbox">
										<ul>
											<li>
												<input id="checkbox" style="width:auto" type="checkbox" name="checkbox" v-model="checkbox" :class="{ 'is-invalid': submitted && $v.checkbox.$error }">
											</li>
											<li>
												<p>{{ $t('contactForm.gdpr') }} <a href="https://webux.sk/osobneudaje.pdf" target="_blank" title="GDPR">{{ $t('contactForm.gdpr2') }}</a></p>
											</li>
										</ul>
										<div v-if="submitted && !$v.checkbox.required" class="invalid-feedback">{{ $t('contactForm.checkbox') }}</div>
									</label>
                <button type="submit">{{ $t('contactForm.submit') }}</button>
                <h6 class="response">{{response}}</h6>
                <h6 class="wrong">{{wrong}}</h6>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setDocumentTitle from '../util'
import emailjs from 'emailjs-com';
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  name: 'Contact',
  data(){
    return{
      name: '',
      email: '',
      subject: '',
      message: '',
      checkbox: '',
      response: '',
      wrong: '',
      submitted: false
    }
  },
  validations: {
    name: {required},
    email: {required, email},
    subject: {required},
    message: {required},
    checkbox: {required},
  },
  methods: {
    sendEmail(e) {
      try {
        this.submitted = true;
        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
       }
        emailjs.sendForm('service_e8mmca7', 'template_5op4afl', e.target,
        'user_0cLpbABXF9SsVELDqxbdF', {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message
        })
          this.response = this.$t('contactForm.response')
          //Hide response
          setTimeout(() => this.response = '', 5000)
          // Reset form field
          this.name = ''
          this.email = ''
          this.subject = ''
          this.message = ''
          this.checkbox = ''

          //Clear validation
          this.submitted = false;
      } catch(error) {
        this.wrong = this.$t('contactForm.wrong')
      }
    },
  },
  mounted(){
      this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return
        }
        setDocumentTitle(this.$t("navigation.contact"))
      },
      { immediate: true }
    )
    }
}
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";
@import "../assets/scss/_mixins.scss";

.contact-area{
  @include views-general;
  height: 120vh;
  @media #{$mobile}{
    height: 160vh;
  }
  @media screen and (max-height: 480px) and (min-height: 300px){
    height: 280vh;
  }
  .form{
    text-align: left;
    @media #{$very_small}{
       text-align: center;
    }
    .mail-form{
      background: $bg;
      .form-inputs{
        padding: 48px;
        @media #{$typing}{
          padding: 16px;
        }
        @media #{md}{
          padding: 24px;
        }
        @include section-title;
        h6{
          margin-top: 24px;
        }
        p{
          font-size: 12px;
          font-weight: 700;
          color: $primary;
          margin: 24px 0px 8px;
        }
        ul{
          li{
            p{
              margin: 0;
              a{
                color: $secondary;
              }
            }
          }
        }
        label{
          width: 100%;
          margin-bottom: 0px;
          input, textarea{
            width: 100%;
            border: none;
            outline: none;
            border-bottom: 1px solid #707070;
            &:focus{
              border-bottom: 1px solid $primary;
            }
          }
          input{
            padding: 5px 5px;
          }
          textarea{
            padding: 5px 5px 100px;
          }
        }
        button{
          border: 1px solid $white;
          outline: none;
          margin-top: 24px;
          padding: 8px 16px;
          background: $bg;
          color: $primary;
          &:hover{
            color: $secondary;
          }
        }
        .response{
          color: $primary;
        }
        .wrong{
          color: red;
        }
      }
    }
  }
}
.invalid-feedback{
    display: inline-block;
}

</style>